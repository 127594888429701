<template>
    <div>
        <h1>Chat</h1>
    </div>
</template>
<script>
export default {
    name: "ChatView",
    data() {
        return {
    
    }
    },
    mounted: function () {
  
    }
}
</script>